import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Link, Paper, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import { getItemUrl } from "../../common/util";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../common/constants";

export default function ItemsShowcase() {
  const siteConfig = useContext(ConfigContext);

  const [products, setProducts] = useState([]);
  const [bestsellers, setBestsellers] = useState([]);
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/product/collection/shop`)
      .then((res) => {
        let sortedByRank = res.data.products.sort((o1, o2) => {
          let rslt = (+o2.rank || 0) - (+o1.rank || 0);
          if (rslt === 0) {
            rslt = o1.title > o2.title ? 1 : -1;
          }
          return rslt;
        });
        setProducts(sortedByRank);
        setBestsellers(sortedByRank.slice(0, 10));
        setFeatured(sortedByRank.filter((o) => o.featured && +o.featured > 0).slice(0, 10));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <Box py={10} sx={{ backgroundColor: "#e3e3e3" }}>
      {featured.length > 0 && (
        <Box px={2} pb={6}>
          <Box mb={4}>
            <Typography variant="h2">Featured</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "2rem", overflowX: "auto", paddingBottom: "20px" }}>
            {featured.map((o, index) => {
              return (
                <Box>
                  <Link component={RouterLink} to={getItemUrl(o)} underline="none" sx={{ color: "inherit" }}>
                    <Paper elevation={0}>
                      <Box sx={{ backgroundColor: "#ECECEC", lineHeight: "0" }}>
                        <img
                          src={o.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                          style={{ width: "300px", aspectRatio: "1", objectFit: "cover" }}
                        />
                      </Box>
                      <Box p={1} textAlign={"center"}>
                        <Typography
                          variant="h6"
                          sx={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "1",
                            "line-clamp": "1",
                            "-webkit-box-orient": "vertical"
                          }}
                        >
                          {o.title}
                        </Typography>
                      </Box>
                    </Paper>
                  </Link>
                </Box>
              );
            })}
            <Button variant="contained" color="secondary" sx={{ minWidth: "300px", fontSize: "20px" }} component={RouterLink} to={`/shop`}>
              View All
            </Button>
          </Box>
        </Box>
      )}
      {bestsellers.length > 0 && (
        <Box px={2} pb={6}>
          <Box mb={4}>
            <Typography variant="h2">Bestsellers</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "2rem", overflowX: "auto", paddingBottom: "20px" }}>
            {bestsellers.map((o, index) => {
              return (
                <Box>
                  <Link component={RouterLink} to={getItemUrl(o)} underline="none" sx={{ color: "inherit" }}>
                    <Paper elevation={0}>
                      <Box sx={{ backgroundColor: "#ECECEC", lineHeight: "0" }}>
                        <img
                          src={o.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                          style={{ width: "300px", aspectRatio: "1", objectFit: "cover" }}
                        />
                      </Box>
                      <Box p={1} textAlign={"center"}>
                        <Typography
                          variant="h6"
                          sx={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "1",
                            "line-clamp": "1",
                            "-webkit-box-orient": "vertical"
                          }}
                        >
                          {o.title}
                        </Typography>
                      </Box>
                    </Paper>
                  </Link>
                </Box>
              );
            })}
            <Button variant="contained" color="secondary" sx={{ minWidth: "300px", fontSize: "20px" }} component={RouterLink} to={`/shop`}>
              View All
            </Button>
          </Box>
        </Box>
      )}
      {siteConfig.id === "djPWIkh9e88MTXm4jYhb" && (
        <Box px={2} pb={6}>
          <Box mb={4}>
            <Typography variant="h2">Celebration Cakes</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "2rem", overflowX: "auto", paddingBottom: "20px" }}>
            {products
              .filter((o) => o.type === "Celebration Cake")
              .map((o, idx) => {
                return (
                  <Box idx={idx}>
                    <Link component={RouterLink} to={getItemUrl(o)} underline="none" sx={{ color: "inherit" }}>
                      <Paper elevation={0}>
                        <Box sx={{ backgroundColor: "#ECECEC", lineHeight: "0" }}>
                          <img
                            src={o.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                            style={{ width: "300px", aspectRatio: "1", objectFit: "cover" }}
                          />
                        </Box>
                        <Box p={1} textAlign={"center"}>
                          <Typography
                            variant="h6"
                            sx={{
                              overflow: "hidden",
                              display: "-webkit-box",
                              "-webkit-line-clamp": "1",
                              "line-clamp": "1",
                              "-webkit-box-orient": "vertical"
                            }}
                          >
                            {o.title}
                          </Typography>
                        </Box>
                      </Paper>
                    </Link>
                  </Box>
                );
              })}
            <Button
              variant="contained"
              color="secondary"
              sx={{ minWidth: "300px", fontSize: "20px" }}
              component={RouterLink}
              to={`/shop#celebration-cake`}
            >
              View More
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
