import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, Container, Grid, IconButton, LinearProgress, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import currency from "currency.js";
import ConfigContext from "../../common/context/ConfigContext";
import { useNavigate } from "react-router";
import ProductVariantSelection from "../component/ProductVariantSelection";
import ProductCustomization from "../component/ProductCustomization";
import { getItemBasePrice, getItemMetadata, getItemUrl, getVariantTotal } from "../../common/util";
import ImageCarousel from "../../common/component/image/ImageCarousel";
import { AppStateContext } from "../../common/context/AppStateContext";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ProductDescription from "../component/ProductDescription";
import Images from "../../common/component/image/Images";
import { Helmet } from "react-helmet";

export default function ProductPage() {
  const siteConfig = useContext(ConfigContext);
  const { cart, updateCartState } = useContext(AppStateContext);

  let { productId, slug } = useParams();
  if (!productId && slug) {
    productId = slug.split("-").pop();
  }

  const navigate = useNavigate();

  const [product, setProduct] = useState("");

  const [loading, setLoading] = useState(true);

  const [quantity, setQuantity] = useState(1);

  const [variant, setVariant] = useState("");

  const [images, setImages] = useState([]);
  const [customization, setCustomization] = useState("");
  const [notes, setNotes] = useState("");

  const [variantPrice, setVariantPrice] = useState("");

  useEffect(() => {
    setLoading(true);
    bkstApi(`/site/${siteConfig.id}/product/${productId}`).then((res) => {
      setProduct(res.data.product);
      setDefaults(res.data.product);

      window.scrollTo(0, 100);
      setLoading(false);
    });
  }, [productId]);

  useEffect(() => {
    if (product) {
      setVariantPrice(getVariantTotal(variant, getItemBasePrice(variant)));
    }
  }, [variant, quantity]);

  const validateQuantity = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleQuantityChange = (value) => {
    const newValue = +quantity + +value;
    if (newValue > 0) {
      setQuantity(newValue);
    }
  };

  const setDefaults = (product) => {
    const defaultSize = product.options?.size?.sort((o1, o2) => +o1.pricing.amount - +o2.pricing.amount)[0];
    const defaultVariant = { size: defaultSize };

    if (product.options.size?.length === 1) {
      setVariant(defaultVariant);
    }

    setQuantity(1);
  };

  const addToCart = (e) => {
    e.preventDefault();

    let utm = "";
    const utmParams = sessionStorage.getItem("utm");
    if (utmParams) {
      utm = JSON.parse(utmParams);
    }

    const item = {
      type: "product",
      refId: product.id,
      product: {
        type: product.type,
        images: [product.images[0], ...images],
        title: product.title,
        description: notes,
        price: product.price,
        rules: product.rules
      },
      quantity,
      variant,
      customization
    };
    const cartId = cart?.id || "";

    bkstApi.post(`/site/${siteConfig.id}/cart`, { item, cartId, utm }).then((res) => {
      updateCartState(res.data.cartId, quantity);
      setDefaults(product);

      navigate(`/shop`);
    });
  };

  // SEO metadata generation
  const generateMetadata = () => {
    if (!product) return null;

    const metadata = getItemMetadata(product);
    const canonicalUrl = getItemUrl(product);
    console.log("canonicalUrl", canonicalUrl);

    const basePrice = currency(metadata.price.min).format();
    const maxPrice = metadata.price.max > metadata.price.min ? ` - ${currency(metadata.price.max).format()}` : "";

    return {
      title: `${product.title} | ${siteConfig.biz}`,
      description: `${product.description?.slice(0, 155)}... Starting at ${basePrice}${maxPrice}. ${
        metadata.dietary?.length ? `${metadata.dietary.join(", ")} options available.` : ""
      }`,
      schema: {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: product.title,
        image: product.images?.[0],
        description: product.description,
        offers: {
          "@type": "AggregateOffer",
          lowPrice: metadata.price.min,
          highPrice: metadata.price.max,
          priceCurrency: "USD",
          availability: product.status === "DRAFT" ? "https://schema.org/OutOfStock" : "https://schema.org/InStock"
        }
      }
    };
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const metadata = getItemMetadata(product);
    const seoData = generateMetadata();

    return (
      <>
        {seoData && (
          <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} />

            {/* Open Graph tags */}
            <meta property="og:title" content={seoData.title} />
            <meta property="og:description" content={seoData.description} />
            <meta property="og:image" content={product.images?.[0]} />
            <meta property="og:type" content="product" />
            <meta property="og:url" content={window.location.href} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seoData.title} />
            <meta name="twitter:description" content={seoData.description} />
            <meta name="twitter:image" content={product.images?.[0]} />

            {/* Schema.org JSON-LD */}
            <script type="application/ld+json">{JSON.stringify(seoData.schema)}</script>
          </Helmet>
        )}
        <Box>
          <Container maxWidth="lg">
            <Box pt={10} pb={20}>
              <form onSubmit={addToCart}>
                <Grid container spacing={6}>
                  <Grid item sm={7} xs={12}>
                    <ImageCarousel images={product.images} style={{ width: "100%", borderRadius: "4px" }} />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Paper elevation={0}>
                      <Box px={3} py={3}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <Box mb={2}>
                              <Typography variant="h3" gutterBottom>
                                {product.title}
                              </Typography>
                              <Typography variant="body2">
                                {currency(metadata.price.min).format()}{" "}
                                {metadata.price.max > metadata.price.min && `- ${currency(metadata.price.max).format()}`}
                              </Typography>
                            </Box>
                            <Box>
                              {metadata.leadTime === 0 && (
                                <Box my={0.75}>
                                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
                                    <ShoppingBagOutlinedIcon style={{ fontSize: "1.2em", marginRight: "3px" }} />
                                    Ready to-go
                                  </Typography>
                                </Box>
                              )}
                              {metadata.leadTime > 0 && (
                                <Box my={0.75}>
                                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
                                    <ScheduleIcon style={{ fontSize: "1.2em", marginRight: "3px" }} />
                                    Pre-order (min {metadata.leadTime} hour notice)
                                  </Typography>
                                </Box>
                              )}
                              {metadata.dietary?.length > 0 && (
                                <Box my={0.75}>
                                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
                                    <RestaurantOutlinedIcon style={{ fontSize: "1.2em", marginRight: "3px" }} />
                                    {metadata.dietary.join(", ")} options
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            {product.description && (
                              <Box>
                                <ProductDescription description={product.description} />
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <ProductVariantSelection variant={variant} options={product.options} onChange={(o) => setVariant(o)} />
                            </Box>
                          </Grid>
                          {product.customization?.inscription && (
                            <Grid item xs={12}>
                              <ProductCustomization
                                options={product.customization}
                                customization={customization}
                                onChange={(o) => setCustomization(o)}
                              />
                            </Grid>
                          )}
                          {product.customization?.notes && (
                            <Grid item sm={12} xs={12}>
                              <TextField
                                id="notes"
                                value={notes}
                                label="Special Request (optional)"
                                name="notes"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={Math.floor(notes.length / 40) + 1}
                                onChange={(e) => setNotes(e.target.value)}
                                helperText="At our discretion, we will try to accommodate your request on a best-effort basis."
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
                              <TextField
                                type="number"
                                name="quantity"
                                label="Quantity"
                                value={quantity}
                                variant="outlined"
                                id="quantity"
                                onChange={(e) => setQuantity(e.target.value)}
                                inputProps={{
                                  sx: { textAlign: "center" }
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton onClick={() => handleQuantityChange(-1)} disabled={quantity === 1}>
                                        <RemoveIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => handleQuantityChange(1)}>
                                        <AddIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                sx={{
                                  width: "125px",
                                  "& .MuiOutlinedInput-root": {
                                    paddingRight: 0,
                                    paddingLeft: 0
                                  },
                                  backgroundColor: "#FFFFFF"
                                }}
                                onKeyPress={validateQuantity}
                              />
                              <Typography variant="h5">{+variantPrice > 0 && currency(variantPrice * quantity).format()}</Typography>
                            </Box>
                          </Grid>
                          {product.customization?.photo && (
                            <Grid item xs={12}>
                              <Paper variant="outlined">
                                <Box p={2}>
                                  <Grid container spacing={2}>
                                    <Grid item sm={4} xs={12}>
                                      <Images pics={images} edit={true} onChange={setImages} folder={`img/r`} limit={1} />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                      <Box>
                                        <Typography variant="caption" color="textSecondary">
                                          For best results, use high-resolution photos in JPEG or PNG format. We will crop the image to achieve the
                                          best possible fit, but we will not alter the design of the uploaded photos.
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Paper>
                            </Grid>
                          )}
                          <Grid item xs={12} textAlign="center">
                            {product.status === "DRAFT" && (
                              <Box my={2}>
                                <Alert severity="error">Sorry, this item is no longer available!</Alert>
                              </Box>
                            )}
                            <Box my={2}>
                              <Button fullWidth size="large" variant="contained" color="primary" type="submit" disabled={product.status === "DRAFT"}>
                                Add to Cart
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}
