import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/remote-config";

firebase.initializeApp({
  apiKey: "AIzaSyBITuslVfrmivsFNZ5639CIwQWmsEKai6o",
  authDomain: "bakestreet-f55a0.firebaseapp.com",
  projectId: "bakestreet-f55a0",
  storageBucket: "bakestreet-f55a0.appspot.com",
  appId: "1:687010686765:web:7abe8ce019d9e3f71ed5a4"
});

export const auth = firebase.auth();

export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 600000;

export const upload = (file, folder) => {
  var storageRef = firebase.storage().ref();
  var uploadTask = storageRef.child(`${folder}/${Date.now()}-${file.name}`).put(file);
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function (snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
        default:
          console.log("default");
      }
    },
    function (error) {
      console.log(error);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          console.log("unauthorized");
          break;

        case "storage/canceled":
          // User canceled the upload
          console.log("canceled");
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          console.log("unknown");
          break;
        default:
          break;
      }
    },
    function () {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        console.log("File available at", downloadURL);
        return downloadURL;
      });
    }
  );

  return uploadTask;
};

export const getFileUrlsFromFolder = async (folder) => {
  let urls = [];
  var storageRef = firebase.storage().ref(folder);
  const files = await storageRef.listAll();

  let promises = [];
  for (let i = 0; i < files.items.length; i++) {
    const imageRef = files.items[i];
    promises.push(imageRef.getDownloadURL());
  }

  urls = await Promise.all(promises);
  return urls;
};

export const getRemoteConfigValue = async (key) => {
  await remoteConfig.fetchAndActivate();
  const value = remoteConfig.getString(key);
  console.log('VALUE', value);
  return value ? JSON.parse(value) : "";
};
