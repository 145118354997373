import React, { useState, useEffect, useContext } from "react";
import StaticPage from "../../common/screen/StaticPage";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Backdrop, Container, CircularProgress, Grid, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import { useStripe } from "@stripe/react-stripe-js";
import { AppStateContext } from "../../common/context/AppStateContext";

export default function PaymentStatus(props) {
  const stripe = useStripe();
  const { resetCartState } = useContext(AppStateContext);

  const [status, setStatus] = useState("");

  const reqId = new URLSearchParams(window.location.search).get("req_id") || "";
  const orderId = new URLSearchParams(window.location.search).get("order_id") || "";
  const invoiceId = new URLSearchParams(window.location.search).get("invoice_id") || "";
  const cartId = new URLSearchParams(window.location.search).get("cart_id") || "";

  const paymentType = new URLSearchParams(window.location.search).get("payment_type") || "";
  const paymentIntentId = new URLSearchParams(window.location.search).get("payment_intent") || "";
  const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret") || "";

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "requires_capture":
          checkPaymentStatus();
          break;

        case "succeeded":
          checkPaymentStatus();
          break;

        case "processing":
          setStatus("timeout");
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setStatus("fail");
          break;

        default:
          setStatus("unknown");
          break;
      }
    });
  }, [stripe]);

  const checkPaymentStatus = () => {
    setStatus("processing");

    if (cartId) {
      resetCartState();
    }

    var count = 0;
    var loopID = setInterval(() => {
      count++;
      if (count > 14) {
        clearInterval(loopID);
        setStatus("timeout");
      } else {
        bkstApi(`/get-payment-status?reqId=${reqId}&orderId=${orderId}&invoiceId=${invoiceId}&cartId=${cartId}`).then((res) => {
          console.log("Success!");
        });
      }
    }, 1000);
  };

  if (!status || status === "processing") {
    return (
      <Container style={{ height: "100vh" }}>
        <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
          <Grid container spacing={2} align="center">
            <Grid item xs={12}>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item xs={12}>
              <Typography>{status === "processing" ? "updating your order..." : "checking payment status"}</Typography>
            </Grid>
          </Grid>
        </Backdrop>
      </Container>
    );
  } else if (status === "timeout") {
    return (
      <StaticPage
        title="Waiting for confirmation"
        desc="We're still waiting for your bank to confirm your payment. Once they confirm your payment, we'll notify you via email & text and confirm your order. This may take upto 1-2 hours."
        icon={CheckCircleOutlineOutlinedIcon}
      ></StaticPage>
    );
  } else if (status === "fail") {
    return (
      <StaticPage
        title="Payment Failed"
        desc="Please try again. If your payment fails again, please use a different payment method or call your bank."
        icon={ErrorOutlineIcon}
      ></StaticPage>
    );
  }
}
